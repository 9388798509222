
import { defineComponent, reactive, toRefs, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import TabPane from './components/TabPane.vue';
export default defineComponent({
  components: {
    TabPane
  },
  setup() {
    const route = useRoute();
    const dataMap = reactive({
      route: route,
      router: useRouter(),
      tabMapOptions: [
        { label: 'China', key: 'CN' },
        { label: 'USA', key: 'US' },
        { label: 'Japan', key: 'JP' },
        { label: 'Eurozone', key: 'EU' }
      ],
      activeName: 'CN',
      createdTimes: 0,
      showCreatedTimes() {
        dataMap.createdTimes = dataMap.createdTimes + 1;
      }
    });
    onMounted(() => {
      const tab = route.query.tab as string;
      if (tab) {
        dataMap.activeName = tab;
      }
    });
    watch(() => dataMap.activeName, (nv) => {
      dataMap.router
        .push(`${dataMap.route.path}?tab=${nv}`)
        .catch((err) => {
          console.warn(err);
        });
    });
    return { ...toRefs(dataMap) };
  }
});
