
import { defineComponent, reactive, toRefs, onMounted } from 'vue';
import { getArticles } from '@/apis/articles';
import { ArticleModel } from '@/model/articleModel';

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'CN'
    }
  },
  emits: ['create'],
  setup(_, ctx) {
    console.log('tabtabtabtabtabatabtabtab');
    const dataMap = reactive({
      list: Array<ArticleModel>(),
      listQuery: {
        page: 1,
        limit: 5,
        type: _.type,
        sort: 'id'
      },
      loading: false,
      async getList() {
        dataMap.loading = true;
        ctx.emit('create');
        const data = await getArticles(dataMap.listQuery);
        dataMap.list = data?.data.items ?? [];
        // Just to simulate the time of the request
        setTimeout(() => {
          dataMap.loading = false;
        }, 0.5 * 1000);
      }
    });
    onMounted(() => {
      dataMap.getList();
    });
    return { ...toRefs(dataMap) };
  }

});
