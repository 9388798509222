import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e290b4fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_TabPane = _resolveComponent("TabPane")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tag, null, {
      default: _withCtx(() => [
        _createTextVNode("mounted times ：" + _toDisplayString(_ctx.createdTimes), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_el_alert, {
      closable: false,
      style: {"width":"200px","display":"inline-block","vertical-align":"middle","margin-left":"30px"},
      title: "Tab with keep-alive",
      type: "success"
    }),
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.activeName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event)),
      style: {"margin-top":"15px"},
      type: "border-card"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabMapOptions, (item) => {
          return (_openBlock(), _createBlock(_component_el_tab_pane, {
            key: item.key,
            label: item.label,
            name: item.key
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_KeepAlive, null, [
                (_ctx.activeName === item.key)
                  ? (_openBlock(), _createBlock(_component_TabPane, {
                      key: 0,
                      type: item.key,
                      onCreate: _ctx.showCreatedTimes
                    }, null, 8, ["type", "onCreate"]))
                  : _createCommentVNode("", true)
              ], 1024))
            ]),
            _: 2
          }, 1032, ["label", "name"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}